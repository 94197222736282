@import './colors.css';

.download_icon {
    cursor: pointer;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--download--icon--color)
}

.upload_icon {
    cursor: pointer;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--download--icon--color)
}

.save_icon {
    cursor: pointer;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--primary--color)
}

.delete_icon {
    cursor: pointer;
    margin-top: 0.4rem;
    font-size: 1.7rem;
    color: var(--declined--color)
}

.download_icon_disabled {
    cursor: not-allowed;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--disabled--color)
}

.upload_icon_disabled {
    cursor: not-allowed;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--disabled--color)
}

.save_icon_disabled {
    cursor: not-allowed;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
    font-size: 1.7rem;
    color: var(--disabled--color)
}

.delete_icon_disabled {
    cursor: not-allowed;
    margin-top: 0.4rem;
    font-size: 1.7rem;
    color: var(--disabled--color)
}